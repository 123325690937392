/* eslint-disable */
//ONLY FOR VSCODE AUTO COMPLETE
import { fabric } from 'fabric';
var FontFaceObserver = require('fontfaceobserver');
//END
var visea = visea || { version: '1.0.0' };
if (typeof exports !== 'undefined') {
  exports.visea = visea;
}
/* _AMD_START_ */
else if (typeof define === 'function' && define.amd) {
  define([], function() { return visea; });
}

/* _AMD_END_ */
if (typeof document !== 'undefined' && typeof window !== 'undefined') {
  if (document instanceof (typeof HTMLDocument !== 'undefined' ? HTMLDocument : Document)) {
    visea.document = document;
  }
  else {
    visea.document = document.implementation.createHTMLDocument('');
  }
  visea.window = window;
}
export { visea };
visea = {};

visea.EDITOR_VISEA = 0;
visea.EDITOR_MULTIMEDIA = 1;
visea.EDITOR_POINT = 3;

visea.NORMAL_MODE = 0;
visea.NEW_MODE = 1;
visea.EDIT_MODE = 2;
visea.ADJ_MODE = 4;
visea.DELETE_MODE = 5;

visea.layers = {};
visea.current_layer = -1;
visea.palette = {};

visea.internals = {
  //Math.random stuff
  min: 99,
  max: 999999,
  //INTERNALS
  editorMode: 0,
  mode: 0,
  useRender: true,
  sharedPoints: new Array(),
  overlays: {},
  graph: {},
  //TMP ARRAYS GENERATE POLYGON
  modifyStructure: {
    polygon_id: null,
    points: new Array()
  },
  pathAutocomplete: {
    max_path_length: 30,
    rendercircle_id: null,
    polygon_id: null,
    points: [],
    fromPoint: null,
    toPoint: null,
    pathPoints: []
  },
  renderNear: {
    polygon_id: null,
    circle_id: null
  },
  adj: {
    target: null,
    parents: null
  },
  sizes: {
    text: 0,
    circle: 5,
    line: 2
  },
  palette: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c', '#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'],
  modifySnapshotPoints: new Array(),
  newPolygonArray: new Array(),
  tmpSharePoints: new Array(),
  lineArray: new Array(),
  renderNearPoints: new Array(),
  activeLine: null,
  activeShape: false,
  canvas: null,
  tollerance: 20,
  viewportTransform: {},
  callbacks: {
    oneTimePolygonClick: [],
    onPolygonClick: null,
    onPointClick: null,
    onPolygonDelete: null,
  }
};

/*
    initCanvas()
    <canvas id="myCanvas"></canvas> -> visea.initCanvas("myCanvas");
    TODO: BETTER setWidth and setHeight
*/
visea.initMode = function(mode){
  if([visea.EDITOR_MULTIMEDIA, visea.EDITOR_VISEA, visea.EDITOR_POINT].includes(mode)){
    visea.internals.editorMode = mode;
    if(mode === 0) {
      visea.internals.useRender = true;
    } else {
      visea.internals.useRender = false;
    }
    return true;
  }else{
    return false;
  }
},

visea.initCanvas =  function(canvasName) {
  visea.internals.canvas = window._canvas = new fabric.Canvas(canvasName);
  //visea.internals.canvas.setWidth(document.body.clientWidth);
  //visea.internals.canvas.setHeight(document.body.clientWidth * 0.56 - offsetHeight);
};

visea.textTest = function(point) {

}
/*
    loadImg(path) -> loadImg('img/myImage.png');
*/
visea.loadImg = async function(path){
  return fabric.Image.fromURL(path, function(oImg) {
    oImg.set('selectable', false);
    //oImg.scaleX = 0.3;
    //oImg.scaleY = 0.3;
    var div_width = visea.internals.canvas.width;
    var div_height = visea.internals.canvas.height;
    visea.internals.canvas.setBackgroundImage(oImg);
    visea.internals.sizes.text = oImg.height;

    if(oImg.width > div_width || oImg.height > div_height) {
      visea.internals.canvas.setWidth(oImg.width);
      visea.internals.canvas.setHeight(oImg.height);
    }
    visea.internals.canvas._objects.forEach(obj => {
      if(obj instanceof fabric.Text) {
        // obj.fontFamily = 'Montserrat';
        obj.fontSize = 0.03125 * visea.internals.sizes.text;
      }
    })
    visea.internals.canvas.defaultCursor = 'move';
    if (div_width / div_height >= div_width / div_height){
      visea.internals.canvas.setZoom(0.9 * (div_height / oImg.height));
      visea.internals.canvas.renderAll();
    }
    else {
      visea.internals.canvas.setZoom(div_width / oImg.width);
      visea.internals.canvas.renderAll();
    }
    /*
    let font = new FontFaceObserver('Monserrat');
    font.load().then( () => {
    })
    */ 
  });
};
/*
    visea.util:
        Util functions collection

    cleanUpRender:
        remove all object with renderCircle attribute
        renderNear() create circle with renderCircle = true and append on canvas when cursor isNear() = true

    isNear:
        return if a point is near to cursor point, based with a tollerance
        default tollerance in visea.internals

    getNearest
        return the nearest point inside renderNearPoints array

    getObjectById:
        Get Fabric Object by his obj.id

    randomRgba
        Get a random color in rgba() format

*/

visea.util = {
  getMode: function(){
    return visea.internals.mode;
  },
  switchMode: function(desiredMode, ctx){
    if (visea.internals.mode === visea.NORMAL_MODE){
      if (desiredMode === visea.NEW_MODE){
        visea.internals.canvas.defaultCursor = 'pointer';
        visea.polygon.drawPolygon();
        visea.internals.canvas.renderAll();
      }
      if (desiredMode === visea.EDIT_MODE){
        // visea.internals.canvas.defaultCursor = 'pointer';
        visea.internals.callbacks.oneTimePolygonClick.push(function(target) {
          if(target instanceof fabric.Polygon) {
            visea.polygon.modifyPolygon(target);
          }
          visea.internals.callbacks.oneTimePolygonClick.pop();
        });
      }
      if (desiredMode === visea.DELETE_MODE) {
        visea.internals.mode = visea.DELETE_MODE;
        // visea.internals.canvas.defaultCursor = 'pointer';
        visea.internals.callbacks.oneTimePolygonClick.push(function(target) {
          if (visea.internals.callbacks.onPolygonDelete && (target instanceof fabric.Text || target instanceof fabric.Polygon)) {
            visea.internals.callbacks.onPolygonDelete('Eliminare poligono?').then((confirmed) => {
              if(confirmed) {
                if(target instanceof fabric.Text) {
                  visea.polygon.deletePolygon(target.polygon);
                }
                if(target instanceof fabric.Polygon) {
                  visea.polygon.deletePolygon(target);
                }
              }
            })
          } else {
            if(target instanceof fabric.Text) {
              visea.polygon.deletePolygon(target.polygon);
            }
            if(target instanceof fabric.Polygon) {
              visea.polygon.deletePolygon(target);
            }
          }
        });
      }

      if (desiredMode === visea.ADJ_MODE) {
        // visea.internals.canvas.defaultCursor = 'pointer';
        visea.internals.callbacks.oneTimePolygonClick.push(function(target) {
          if(target instanceof fabric.Polygon) {
            visea.polygon.AdjacencyPolygon(target);
            visea.internals.callbacks.oneTimePolygonClick.pop();
          }
          if(target instanceof fabric.Text) {
            visea.polygon.AdjacencyPolygon(target.polygon);
            visea.internals.callbacks.oneTimePolygonClick.pop();
          }
        })
      }
      return true;
    }
    if (visea.internals.mode === visea.NEW_MODE) {
      if(desiredMode === visea.NORMAL_MODE){
        visea.util.cleanUpRender();
        visea.util.cleanUpDraw();
        visea.internals.mode = visea.NORMAL_MODE;
        visea.internals.canvas.defaultCursor = 'move';
        visea.internals.canvas.renderAll();
      }
      return false;
    }
    if (visea.internals.mode === visea.EDIT_MODE){
      if(desiredMode === visea.NORMAL_MODE) {
        // visea.internals.canvas.defaultCursor = 'move';
        visea.internals.canvas.renderAll();
        visea.polygon.commitModify(visea.util.getObjectById(visea.internals.modifyStructure.polygon_id));
      }
      return false;
    }
    if (visea.internals.mode === visea.DELETE_MODE) {
      if(desiredMode === visea.NORMAL_MODE) {
        // visea.internals.canvas.defaultCursor = 'move';
        visea.internals.canvas.renderAll();
        visea.internals.callbacks.oneTimePolygonClick.pop();
        visea.internals.mode = visea.NORMAL_MODE;
      }
    }
    if (visea.internals.mode === visea.ADJ_MODE) {
      if(desiredMode === visea.NORMAL_MODE) {
        // visea.internals.canvas.defaultCursor = 'move';
        visea.internals.canvas.renderAll();
        visea.polygon.AdjacencyPolygon(visea.util.getObjectById(visea.internals.adj.target));
        visea.internals.mode = visea.NORMAL_MODE;
      }
    }
  },
  getCanvas: function () {
    return visea.internals.canvas;
  },
  cleanUpRender: function() {
    visea.internals.canvas.getObjects().forEach(obj => {
      if (typeof obj !== 'undefined') {
        if (typeof obj.renderCircle !== 'undefined' || typeof obj.renderLine !== 'undefined'){
          visea.internals.canvas.remove(obj);
        }
      }
    });
  },

  cleanUpDraw: function() {
    visea.internals.newPolygonArray = [];
    visea.internals.tmpSharePoints = [];
    visea.internals.lineArray = [];
    visea.internals.renderNearPoints = [];
    if(visea.internals.activeLine){
      visea.util.getCanvas().remove(visea.internals.activeLine);
      visea.internals.activeLine = null;
    }
    if(visea.internals.activeShape){
      visea.util.getCanvas().remove(visea.internals.activeShape);
      visea.internals.activeShape = null;
    }
    visea.internals.canvas.getObjects().forEach(obj => {
      if (typeof obj !== 'undefined') {
        if (obj instanceof fabric.Circle || obj instanceof fabric.Line){
          visea.internals.canvas.remove(obj);
        }
      }
    });
  },

  isNear: function(targetPoint, cursorPoint, tollerance = null){
    if (!tollerance) {tollerance = visea.internals.tollerance;}
    var calc_x = Math.abs(targetPoint.x - cursorPoint.x);
    var calc_y = Math.abs(targetPoint.y - cursorPoint.y);
    if (calc_x < tollerance && calc_y < tollerance){
      return true;
    }
    else {
      return false;
    }
  },
  getNearest: function(point){
    var nearest = null;
    var _x = null;
    var _y = null;
    visea.internals.renderNearPoints.forEach(nearPoint => {
      let x = null;
      let y = null;
      if (_x === null && _y === null){
        _x = Math.abs(nearPoint.x - point.x);
        _y = Math.abs(nearPoint.y - point.y);
        nearest = nearPoint;
      }
      else {
        x = Math.abs(nearPoint.x - point.x);
        y = Math.abs(nearPoint.y - point.y);
        if (_x > x && _y > y){
          _x = x;
          _y = y;
          nearest = nearPoint;
        }

      }
    });
    return visea.util.isNear({x: _x, y: _y}, point, visea.internals.tollerance * 50) === true ? visea.util.getObjectById(nearest.id) : null;
  },
  getNearestEx: function(point, candidates){
    var nearest = null;
    var _x = null;
    var _y = null;
    candidates.forEach(candidate => {
      let x = null;
      let y = null;
      if (_x === null && _y === null){
        _x = Math.abs(candidate.x - point.x);
        _y = Math.abs(candidate.y - point.y);
        nearest = candidate;
      }
      else {
        x = Math.abs(candidate.x - point.x);
        y = Math.abs(candidate.y - point.y);
        if (_x > x && _y > y){
          _x = x;
          _y = y;
          nearest = candidate;
        }

      }
    });
    return nearest;
  },
  getObjectById: function(id){
    let objects = visea.internals.canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      const element = objects[i];
      if (typeof element.id !== 'undefined'){
        if (element.id == id){
          return element;
        }
      }

    }
    return null;
  },
  randomRgba: function() {
    var o = Math.round, r = Math.random, s = 230;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')';
  },
  generateCircle: function(left, top) {
    var random = Math.floor(Math.random() * (visea.internals.max - visea.internals.min + 1)) + visea.internals.min;
    var id = new Date().getTime() + random;
    var circle = new fabric.Circle({
      radius: visea.internals.sizes.circle / visea.util.getCanvas().getZoom(),
      fill: '#ffffff',
      stroke: '#333333',
      strokeWidth: 0.5 / visea.util.getCanvas().getZoom(),
      left: left,
      top: top,
      selectable: false,
      hasBorders: false,
      hasControls: false,
      originX: 'center',
      originY: 'center',
      id: id,
      objectCaching: false
    });
    return circle;

  },
  setViseaPoint: function(pos) {
    let circle = visea.util.getObjectById("visea-point")
    if(circle !== null){
      circle.left = pos.x,
      circle.top = pos.y
      circle.radius = (0.0047 * visea.util.getCanvas().height) / visea.util.getCanvas().getZoom();
      circle.strokeWidth = 3 / visea.util.getCanvas().getZoom();
      visea.util.getCanvas().renderAll()
    } else {
      circle = new fabric.Circle({
        radius: 15 / visea.util.getCanvas().getZoom(),
        fill: '#f2ff00',
        stroke: '#000000',
        strokeWidth: 3 / visea.util.getCanvas().getZoom(),
        left: (pos.x),
        top: (pos.y),
        selectable: true,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        id: "visea-point",
        renderCircle: true,
        objectCaching: false
      });
      visea.util.getCanvas().add(circle)
    }
  },
  getLastPoint: function() {
    if (visea.internals.mode === visea.NEW_MODE) {return visea.internals.newPolygonArray[visea.internals.newPolygonArray.length - 1];}
    return null;
  },
  isShared: function(point){
    for (let i = 0; i < visea.internals.sharedPoints.length; i++){
      if (visea.internals.sharedPoints[i].x == point.x && visea.internals.sharedPoints[i].y == point.y){
        return i;
      }
    }
    return -1;
  },
  addLayer: function(label, color){
    visea.layers[label] = new Array();
    visea.current_layer = label;
    visea.palette[label] = color;
  },
  exportDay: function(day){
    let exportObject = {};
    exportObject[day] = new Array();
    visea.layers[day].forEach(polygon_id => {
      var polygonPoints = new Array();
      visea.util.getObjectById(polygon_id).points.forEach(point => {
        polygonPoints.push({x: point.x, y: point.y});
      });
      exportObject[day].push(polygonPoints);
    });
    return JSON.stringify(exportObject);
  },
  exportPolygons: function(){
    let exportObject = [];
    Object.keys(visea.layers).forEach(key => {
      visea.layers[key].forEach(polygon_id => {
        var polygonPoints = new Array();
        visea.util.getObjectById(polygon_id).points.forEach(point => {
          polygonPoints.push([point.x, point.y]);
        });
        exportObject.push(polygonPoints);
      });
    });
    return JSON.stringify(exportObject);
  },
  importPolygons: function(polygonsStruct){
    if(polygonsStruct === null || polygonsStruct === undefined) {
      return;
    }
    var polygonsArray  = JSON.parse(polygonsStruct);
    visea.layers[0] = new Array();
    let polycount = 0;
    polygonsArray.forEach(polygonPoints => {
      let points = []
      polygonPoints.forEach(point => {
        points.push({x: point[0], y: point[1]});
      })
      var polyrandom = Math.floor(Math.random() * (visea.internals.max - visea.internals.min + 1)) + visea.internals.min;
      var polyid = new Date().getTime() + polyrandom;
      let polygon = new fabric.Polygon(points, {
        stroke: '#333333',
        strokeWidth: 0.5,
        fill: visea.internals.palette[polycount % visea.internals.palette.length],
        opacity: 0.7,
        hasBorders: true,
        hasControls: true,
        selectable: false,
        objectCaching: false,
        edit: false,
        id: polyid
      });
      let polygon_center = polygon.getCenterPoint()

      polygon.day_label = new fabric.Text("" + polycount++, {
        fontSize: 0.03125 * visea.internals.sizes.text,
        fontFamily: 'Montserrat',
        fill: 'white',
        originX: 'center',
        originY: 'center',
        selectable: false,
        left: polygon_center.x,
        top: polygon_center.y,
        polygon: polygon,
        opacity: 1.0
      });
      visea.internals.canvas.add(polygon);
      visea.internals.canvas.add(polygon.day_label);
      visea.internals.canvas.bringToFront(polygon.day_label);
      visea.internals.canvas.renderAll();
      visea.polygon.controls.hookControls(polygon);
      visea.util.getObjectById(polyid).lines = visea.polygon.getLines(polyid);
      visea.internals.overlays[polyid] = [];
      visea.layers[0].push(polyid);
    });
  },

  exportSession: function() {
    let output = {}
    output.layers = visea.layers;
    output.palette = visea.palette;
    output.sharedPoints = visea.internals.sharedPoints
    output.polygons = []
    Object.keys(visea.layers).forEach(key => {
      visea.layers[key].forEach(polygon_id => {
        var polygonPoints = new Array();
        visea.util.getObjectById(polygon_id).points.forEach(point => {
          polygonPoints.push({x: point.x, y: point.y});
        });
        output.polygons.push({id: polygon_id, points: polygonPoints});
      });
    });
    output.overlays = []
    Object.keys(visea.internals.overlays).forEach(poly_id => {
      output.overlays.push({
                            'id': poly_id, 
                            parents: visea.internals.overlays[poly_id],
                            'label': visea.util.getObjectById(poly_id).day_label.text
                          });
    })
    output.graph = visea.internals.graph;
    return JSON.stringify(output)
  },

  importSession: function(jsonString) {
    var exportObject  = JSON.parse(jsonString);
    if(exportObject == null) {
      return;
    }
    if(exportObject.layers !== undefined){
      visea.layers = exportObject.layers;
    }
    if(exportObject.palette !== undefined){
      visea.palette = exportObject.palette;
    }
    if(exportObject.sharedPoints !== undefined){
      visea.internals.sharedPoints = exportObject.sharedPoints;
    }
    let polycount = 0;
    if(exportObject.polygons !== undefined) {
      exportObject.polygons.forEach(poly => {

        let polygon = new fabric.Polygon(poly.points, {
          stroke: '#333333',
          strokeWidth: 0.5,
          fill: visea.internals.palette[polycount % visea.internals.palette.length],
          opacity: 0.7,
          hasBorders: true,
          hasControls: true,
          selectable: false,
          objectCaching: false,
          edit: false,
          id: poly.id
        });
        let polygon_center = polygon.getCenterPoint()
        polygon.day_label = new fabric.Text("" + polycount++, {
          fontSize: 0.03125 * visea.internals.sizes.text,
          fontFamily: 'Montserrat',
          fill: 'white',
          originX: 'center',
          originY: 'center',
          selectable: false,
          left: polygon_center.x,
          top: polygon_center.y,
          polygon: polygon,
          opacity: 1.0
        });
        visea.internals.canvas.add(polygon);
        visea.internals.canvas.add(polygon.day_label);
        visea.internals.canvas.bringToFront(polygon.day_label);
        visea.internals.canvas.renderAll();
        visea.polygon.controls.hookControls(polygon);
        visea.util.getObjectById(poly.id).lines = visea.polygon.getLines(poly.id);
        visea.internals.overlays[poly.id] = []
      });
    }
    if(exportObject.overlays !== undefined) {
      exportObject.overlays.forEach(poly => {
        visea.internals.overlays[poly.id] = poly.parents;
      })
    }
    if(exportObject.graph !== undefined) {
      visea.internals.graph = exportObject.graph;
    }
    return true;
  },

  deleteDay: function(day){
    visea.layers[day].forEach(polyId => {
      visea.polygon.deletePolygon(visea.util.getObjectById(polyId));
    });
    delete visea.layers[day];
    return true;
  },
  isValid: function(pos) {
    if (pos.x < 0 || pos.y < 0) {return false;}
    if (pos.x > visea.util.getCanvas().width || pos.y > visea.util.getCanvas().height) {return false;}
    return true;
  },
  onPolygonClick: function(callback){
    visea.internals.callbacks.onPolygonClick = callback;
  },
  removePolygonClickCallback: function() {
    visea.internals.callbacks.onPolygonClick = null;
  }

};

/*
    visea.polygon
        All Polygon functions

    drawPolygon
        clean up and setup new polygon drowing set visea mode to NEW_MODE

    addPoint
        based on canvas.on('mouse:down') event, choose to add a point in different ways
        - Standard point: When target is null or point and isShared = false, a normal point is pushed in current polygon
        - Shared point: When is hitted a circle with renderCircle=true the point will be added to current polygon and pushed to shared-point list
        - Point attach: When the target has not renderCircle tag, addPoint try to find nearest point (can be choosen a different tollerance for isNear)
                        And will use the nearest point as next point
        TODO:
            Path complete:
*/
visea.polygon = {
  drawPolygon: function() {
    if (visea.internals.mode === visea.NORMAL_MODE && visea.internals.editorMode !== visea.EDITOR_POINT){
      visea.internals.mode = visea.NEW_MODE;
      visea.internals.newPolygonArray = new Array();
      visea.internals.lineArray = new Array();
      visea.internals.tmpSharePoints = new Array();
      visea.internals.activeLine;
    }
  },
  addPoint: function(options) {
    var pos = visea.util.getCanvas().getPointer(options.e);
    function onCascadeAutocomplete(){
      let output = {success: true, length: 0, tmpSharePoints: null};
      let tmpSharePoints = [];
      function pushToTmp(point, safe){
        if (safe === true){
          for (let i = 0; i < visea.internals.tmpSharePoints.length; i++) {
            const element = visea.internals.tmpSharePoints[i];
            if (element.x == point.x && element.y == point.y){
              return false;
            }
          }
        }
        tmpSharePoints.push(point);
        return true;
      }
      if (visea.internals.pathAutocomplete.points.length > 0){
        for (let i = 0; i < visea.internals.pathAutocomplete.points.length; i++) {
          const element = visea.internals.pathAutocomplete.points[i];
          if (!pushToTmp({x: element.x, y: element.y, parent_id: visea.internals.pathAutocomplete.polygon_id})){
            output.success = false;
            output.tmpSharePoints = null;
            return output;
          }
          output.length += 1;
        }
        output.tmpSharePoints = tmpSharePoints;
        return output;
      }
    }
    function addCascadePoints(tmpSharePoints){
      if (visea.internals.activeShape){
        var points = visea.internals.activeShape.get('points');
        points.pop();
        tmpSharePoints.forEach(point => {
          var circle = visea.util.generateCircle(point.x, point.y);
          circle.set({fill: 'blue'});
          visea.internals.tmpSharePoints.push(point);
          points.push({x: point.x , y: point.y});
          visea.internals.newPolygonArray.push(circle);
          visea.internals.canvas.add(circle);
        });
        var polygon = new fabric.Polygon(points,{
          stroke: '#333333',
          strokeWidth: 1,
          fill: '#cccccc',
          opacity: 0.3,
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
          objectCaching: false
        });
        visea.internals.canvas.remove(visea.internals.activeShape);
        visea.internals.canvas.add(polygon);
        visea.internals.activeShape = polygon;
        visea.internals.canvas.renderAll();
      }
    }
    var target = typeof options.target === 'undefined' ? null : options.target;
    var isSharing = false;
    if (target !== null){
      if (target.renderCircle === true){
        isSharing = true;
        for (let i = 0; i < visea.internals.tmpSharePoints.length; i++) {
          const element = visea.internals.tmpSharePoints[i];
          if (element.x == (target.left) && element.y == (target.top)){
            return false;
          }
        }
        if (visea.internals.pathAutocomplete.points !== null){
          if (visea.internals.pathAutocomplete.points.length > 0) {
            let onCascadeObj = onCascadeAutocomplete();
            if (onCascadeObj.success){
              addCascadePoints(onCascadeObj.tmpSharePoints);
            }
          }
        }
        visea.internals.tmpSharePoints.push({
          parent_id: target.parent_id,
          x: target.left,
          y: target.top
        });
      }
      else if (visea.internals.renderNearPoints.length > 0){
        let tmp_target = visea.util.getNearest({x: pos.x, y: pos.y});
        if (tmp_target !== null){
          target = tmp_target;
          isSharing = true;
          for (let i = 0; i < visea.internals.tmpSharePoints.length; i++) {
            const element = visea.internals.tmpSharePoints[i];
            if (element.x == (target.left) && element.y == (target.top)){
              return false;
            }
          }
          visea.internals.tmpSharePoints.push({
            parent_id: target.parent_id,
            x: target.left,
            y: target.top
          });
        }
      }
    }
    pos = visea.util.getCanvas().getPointer(options.e);
    var circle = isSharing === true ? visea.util.generateCircle(target.left, target.top) : visea.util.generateCircle(pos.x, pos.y);
    if (isSharing === true){
      circle.set({fill: 'blue'});
    }
    else if (visea.internals.newPolygonArray.length == 0){
      circle.set({fill: 'red'});
      circle.isStart = true;
    }
    var points = isSharing === true ? [(target.left),(target.top),(target.left),(target.top)] : [(pos.x),(pos.y),(pos.x),(pos.y)];
    let line = new fabric.Line(points, {
      strokeWidth: visea.internals.sizes.line / visea.util.getCanvas().getZoom(),
      fill: '#999999',
      stroke: '#999999',
      'class': 'line',
      originX: 'center',
      originY: 'center',
      selectable: false,
      hasBorders: false,
      hasControls: false,
      evented: false,
      objectCaching: false,
      renderLine: true
    });
    if (visea.internals.activeShape){
      if (isSharing){
        points = visea.internals.activeShape.get('points');
        points.push({
          x: target.left,
          y: target.top
        });
      }
      else {
        points = visea.internals.activeShape.get('points');
        points.push({
          x: pos.x,
          y: pos.y
        });
      }
      var polygon = new fabric.Polygon(points,{
        stroke: '#333333',
        strokeWidth: 1,
        fill: '#cccccc',
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false
      });
      visea.internals.canvas.remove(visea.internals.activeShape);
      visea.internals.canvas.add(polygon);
      visea.internals.activeShape = polygon;
      visea.internals.canvas.renderAll();
    }
    else {
      var polyPoint = [{x: pos.x, y: pos.y}];
      polygon = new fabric.Polygon(polyPoint,{
        stroke: '#333333',
        strokeWidth: 1,
        fill: '#cccccc',
        opacity: 0.3,
        selectable: false,
        hasBorders: false,
        hasControls: false,
        evented: false,
        objectCaching: false
      });
      visea.internals.activeShape = polygon;
      visea.internals.canvas.add(polygon);
    }
    visea.internals.activeLine = line;
    visea.internals.newPolygonArray.push(circle);
    visea.internals.lineArray.push(line);
    visea.internals.canvas.add(line);
    visea.internals.canvas.add(circle);
    visea.internals.canvas.selection = false;
  },
  generatePolygon: function(){
    var points = new Array();
    visea.internals.newPolygonArray.forEach(point => {
      points.push({
        x: point.left,
        y: point.top
      });
      visea.internals.canvas.remove(point);
    });
    visea.internals.lineArray.forEach(line => {
      visea.internals.canvas.remove(line);
    });
    visea.internals.canvas.remove(visea.internals.activeShape).remove(visea.internals.activeLine);
    var polyrandom = Math.floor(Math.random() * (visea.internals.max - visea.internals.min + 1)) + visea.internals.min;
    var polyid = new Date().getTime() + polyrandom;
    var polygon = new fabric.Polygon(points,{
      stroke: '#333333',
      strokeWidth: 0.5,
      fill: visea.internals.palette[visea.layers[visea.current_layer].length % visea.internals.palette.length],
      opacity: 0.5,
      hasBorders: true,
      hasControls: true,
      selectable: false,
      objectCaching: false,
      edit: false,
      id: polyid
    });
    visea.polygon.controls.hookControls(polygon);
    visea.internals.canvas.add(polygon);
    let polygon_center = polygon.getCenterPoint()
    polygon.day_label = new fabric.Text(""+visea.layers[visea.current_layer].length, {
      fontSize: 0.03125 * visea.internals.sizes.text,
      fontFamily: 'Montserrat',
      fill: 'white',
      originX: 'center',
      originY: 'center',
      selectable: false,
      left: polygon_center.x,
      top: polygon_center.y,
      polygon: polygon,
    });
    visea.internals.canvas.add(polygon.day_label);
    visea.internals.canvas.bringToFront(polygon.day_label);
    visea.internals.canvas.renderAll();
    visea.util.getObjectById(polyid).lines = visea.polygon.getLines(polyid);
    visea.internals.activeLine = null;
    visea.internals.activeShape = null;
    visea.internals.mode = visea.NORMAL_MODE;
    visea.internals.canvas.selection = true;
    function pushShared(point, newPolygon_id){
      for (let i = 0; i < visea.internals.sharedPoints.length; i++){
        if (visea.internals.sharedPoints[i].x == point.x && visea.internals.sharedPoints[i].y == point.y){
          visea.internals.sharedPoints[i].polygons_id.push(newPolygon_id);
          return true;
        }
      }
      visea.internals.sharedPoints.push({x: point.x,
        y: point.y,
        polygons_id: [point.parent_id, newPolygon_id]
      });
      return true;
    }
    visea.internals.tmpSharePoints.forEach(element => {
      pushShared(element, polygon.id);
    });
    visea.layers[visea.current_layer].push(polyid);
    visea.internals.overlays[polyid] = [];
    //visea.internals.newPolygonArray = new Array();
    visea.util.cleanUpRender();
  },
  deletePolygon: function(polygon){
    function arrayRemove(arr, value) {
      return arr.filter(function(ele){
        return ele != value;
      });
    }
    visea.internals.sharedPoints.forEach(point => {
      point.polygons_id = arrayRemove(point.polygons_id, polygon.id);
    });
    delete(visea.internals.overlays[polygon.id]);
    Object.keys(visea.internals.overlays).forEach( polyId => {
      if (visea.internals.overlays[polyId].includes(polygon.id)) {
        visea.internals.overlays[polyId] = visea.internals.overlays[polyId].filter(elem => elem !== polygon.id);
      }
    })
    Object.keys(visea.layers).forEach(layer => {
      visea.layers[layer] = visea.layers[layer].filter(polyId => polyId !== polygon.id)
    });
    if(visea.internals.graph.depth !== undefined) {
      if(visea.internals.graph.depth[polygon.id] !== undefined) {
        delete(visea.internals.graph.depth[polygon.id]);
      }
    }
    visea.util.getCanvas().remove(polygon.day_label);
    visea.util.getCanvas().remove(polygon);
    visea.util.getCanvas().renderAll();
    return true;
  },
  deletePolygonPoint: function(polygon, point){
    let index = visea.util.isShared(point);
    if (index !== -1){
      visea.internals.sharedPoints[index].polygons_id = visea.internals.sharedPoints[index].polygons_id.filter(polygon_id =>{
        return polygon_id !== polygon.id;
      });
    }
    polygon.points = polygon.points.filter(polygon_point => {
      return polygon_point.x !== point.x && polygon_point.y !== point.y;
    });
    visea.util.getCanvas().renderAll();
    return true;
  },
  renderNear: function(targetPolygon, cursorPoint) {
    function isShared(point){
      for (let i = 0; i < visea.internals.sharedPoints.length; i++){
        if (visea.internals.sharedPoints[i].x == point.x && visea.internals.sharedPoints[i].y == point.y){
          return true;
        }
      }
      return false;
    }
    function clearList(cursorPoint){
      var newNearPoint = new Array();
      var toRemove = new Array();
      if (visea.internals.renderNearPoints.length == 0) {
        return false;
      }
      visea.internals.renderNearPoints.forEach(point => {
        if (!visea.util.isNear(point, cursorPoint)){
          toRemove.push(point.id);
        }
        else {
          newNearPoint.push(point);
        }
      });
      visea.internals.canvas._objects.filter(obj => obj.renderCircle == true).forEach(obj => {
        if (toRemove.includes(obj.id)){
          visea.internals.canvas.remove(obj);
        }
      });

      toRemove = [];
      visea.internals.renderNearPoints = newNearPoint;
      return true;
    }
    function renderPoint(polygonPoint) {
      function pushPoint(point){
        visea.internals.renderNearPoints.forEach(currentPoint => {
          if (currentPoint.x == point.x && currentPoint.y == point.y) {
            return false;
          }
        });
        visea.internals.renderNearPoints.push({
          id: point.canvas_id,
          x: point.x,
          y: point.y
        });
        return true;
      }
      //TODO use generateCircle
      var random = Math.floor(Math.random() * (visea.internals.max - visea.internals.min + 1)) + visea.internals.min;
      var id = new Date().getTime() + random;
      var circle = new fabric.Circle({
        radius: visea.internals.sizes.circle / visea.util.getCanvas().getZoom(),
        fill: '#ffffff',
        stroke: '#333333',
        strokeWidth: 0.5 / visea.util.getCanvas().getZoom(),
        left: (polygonPoint.x),
        top: (polygonPoint.y),
        selectable: false,
        hasBorders: false,
        hasControls: false,
        originX: 'center',
        originY: 'center',
        id: id,
        parent_id: polygonPoint.parent_id,
        renderCircle: true,
        objectCaching: false
      });
      if (isShared(polygonPoint)){
        circle.set({
          fill: 'green'
        });
      }
      if (pushPoint({
        canvas_id: id,
        x: polygonPoint.x,
        y: polygonPoint.y})){
        visea.internals.canvas.add(circle);
        visea.internals.canvas.selection = false;
      }
    }
    targetPolygon.points.forEach(point => {
      if (visea.util.isNear(point, cursorPoint)){
        point.parent_id = targetPolygon.id;
        renderPoint(point);
      }
    });
    clearList(cursorPoint);
  },
  getIndexFromPoint: function(point, polygon_id){
    var points = visea.util.getObjectById(polygon_id).points;
    if (typeof points !== 'undefined'){
      for (let i = 0; i < points.length; i++){
        if (points[i].x == point.x && points[i].y == point.y){
          return i;
        }
      }
    }
    return -1;
  },
  drawPath: function(fromPoint, toPoint, polygonId){
    var polygonPoints = visea.util.getObjectById(polygonId).points;
    var points = null;
    function getPath(indexFrom, indexTo, polygonPoint){
      var clock_points = [];
      var anticlock_points = [];
      for (let clock = indexFrom + 1, anticlock = indexFrom - 1;; clock++, anticlock--){
        clock = clock % polygonPoint.length;
        if (clock === indexTo){
          break;
        }
        clock_points.push(polygonPoints[clock]);
        if (anticlock === -1) {
          anticlock = polygonPoint.length - 1;
        }
        if (anticlock === indexTo){
          break;
        }
        anticlock_points.push(polygonPoints[anticlock]);
      }
      return clock_points.length > anticlock_points.length ? anticlock_points : clock_points;
    }
    let indexFrom = this.getIndexFromPoint(fromPoint, polygonId);
    let indexTo = this.getIndexFromPoint(toPoint, polygonId);
    if (indexFrom == -1 || indexTo == -1) {return null;}
    points = getPath(indexFrom, indexTo, polygonPoints);
    if (points.length >= visea.internals.pathAutocomplete.max_path_length){
      return null;
    }
    if (visea.internals.pathAutocomplete.pathPoints.length !== 0){
      visea.internals.pathAutocomplete.pathPoints.forEach(point_id => {
        visea.util.getCanvas().remove(visea.util.getObjectById(point_id));
      });
      visea.internals.pathAutocomplete.pathPoints = [];
      visea.util.getCanvas().renderAll();
    }
    points.forEach(point => {
      var circle = visea.util.generateCircle(point.x, point.y);
      circle.set({fill: 'blue'});
      visea.internals.pathAutocomplete.pathPoints.push(circle.id);
      visea.util.getCanvas().add(circle);
    });

    visea.util.getCanvas().renderAll();
    return points;
  },
  getLines(polygonId){
    var lines = new Array();
    var points = visea.util.getObjectById(polygonId).points;
    for (let i = 0; i < points.length; i++){
      if (i == points.length - 1){
        lines.push({0: points[i], 1: points[0]});
      }
      else {
        lines.push({0: points[i], 1: points[i + 1]});
      }
    }
    return lines;
  },
  slopeFormula(point, raw_line){
    let slope = (raw_line[0].y - raw_line[1].y) / (raw_line[0].x - raw_line[1].x);
    let y = point.y - raw_line[0].y;
    let x = slope * (point.x - raw_line[0].x);
    return true;
  },
  getNearLinePoint(point, raw_line){
    function getY(point, raw_line){
      let slope = (raw_line[0].y - raw_line[1].y) / (raw_line[0].x - raw_line[1].x);
      return slope * (point.x - raw_line[0].x) + raw_line[0].y;
    }
    function getX(point, raw_line){
      let slope = (raw_line[0].y - raw_line[1].y) / (raw_line[0].x - raw_line[1].x);
      return raw_line[0].x + ((point.y - raw_line[0].y) / slope);
    }
    let max_diff_x = Math.abs(raw_line[0].x - raw_line[1].x);
    let max_diff_y = Math.abs(raw_line[0].y - raw_line[1].y);
    let calc_x = Math.abs(raw_line[0].x - point.x);
    let calc_y = Math.abs(raw_line[0].y - point.y);
    if (calc_x <= max_diff_x && calc_y <= max_diff_y){
      if (calc_x <= calc_y){
        let newY = getY(point, raw_line);
        return {x: point.x, y: newY, line: {0: {x: raw_line[0].x, y: raw_line[0].y},
          1: {x: raw_line[1].x, y: raw_line[1].y}}};
      }
      else {
        let newX = getX(point, raw_line);
        return {x: newX, y: point.y, line: {0: {x: raw_line[0].x, y: raw_line[0].y},
          1: {x: raw_line[1].x, y: raw_line[1].y}}};
      }
    }
    else {
      if (max_diff_x < 5){
        if (calc_y <= max_diff_y){
          return {x: raw_line[0].x , y: point.y, line: {0: {x: raw_line[0].x, y: raw_line[0].y},
            1: {x: raw_line[1].x, y: raw_line[1].y}}};
        }
      }
      else if (max_diff_y < 5){
        if (calc_x <= max_diff_x){
          return {x: point.x , y: raw_line[0].y, line: {0: {x: raw_line[0].x, y: raw_line[0].y},
            1: {x: raw_line[1].x, y: raw_line[1].y}}};
        }
      }
    }
    return null;
  },
  modifyPolygon(polygon){
    if (visea.internals.mode === visea.NORMAL_MODE && visea.internals.editorMode !== visea.EDITOR_POINT){
      visea.internals.mode = visea.EDIT_MODE;
      visea.internals.modifyStructure.polygon_id = polygon.id;
      polygon.points.forEach(point => {
        visea.internals.modifyStructure.points.push(point);
      });
      polygon.set('selectable', true);
      visea.internals.canvas.setActiveObject(polygon);
    }
  },
  commitModify(polygon) {
    var points = visea.internals.modifyStructure.points;
    var indexShared = -1;
    let tmp_polygon = null;
    if (polygon.id !== visea.internals.modifyStructure.polygon_id) {return false;}
    for (let i = 0; i < points.length; i++) {
      indexShared = visea.util.isShared(points[i]);
      if (indexShared !== -1){
        if (points[i].x !== polygon.points[i].x || points[i].y !== polygon.points[i].y){
          visea.internals.sharedPoints[indexShared].polygons_id.forEach(polygonId => {
            if (polygonId !== polygon.id){
              tmp_polygon = visea.util.getObjectById(polygonId);
              if (tmp_polygon !== null){
                for (let j = 1; j < tmp_polygon.points.length; j++){
                  if (tmp_polygon.points[j].x == points[i].x && tmp_polygon.points[j].y == points[i].y){
                    tmp_polygon.points[j].x = polygon.points[i].x;
                    tmp_polygon.points[j].y = polygon.points[i].y;
                  }
                }
              }
            }
          });
          visea.internals.sharedPoints[indexShared].x = polygon.points[i].x;
          visea.internals.sharedPoints[indexShared].y = polygon.points[i].y;
        }
      }
    }
    visea.internals.modifyStructure.polygon_id = null;
    visea.internals.modifyStructure.points = new Array();
    polygon.set('selectable', false);
    visea.internals.canvas.discardActiveObject();
    visea.internals.mode = visea.NORMAL_MODE;
    visea.util.getObjectById(polygon.id).lines = visea.polygon.getLines(polygon.id);
    visea.util.getCanvas().renderAll();
  },
  commitModifyOnRelease() {
    if (visea.internals.modifyStructure.polygon_id === null || visea.internals.modifyStructure.points.length < 1){
      return false;
    }
    var points = visea.internals.modifyStructure.points;
    var indexShared = -1;
    var polygon = visea.util.getObjectById(visea.internals.modifyStructure.polygon_id);
    var polygon_center = polygon.getCenterPoint();
    if(polygon.day_label != 'undefined') {
      polygon.day_label.left = polygon_center.x;
      polygon.day_label.top = polygon_center.y;
    }
    let tmp_polygon = null;

    for (let i = 0; i < points.length; i++) {
      indexShared = visea.util.isShared(points[i]);
      if (indexShared !== -1){
        if (points[i].x !== polygon.points[i].x || points[i].y !== polygon.points[i].y){
          visea.internals.sharedPoints[indexShared].polygons_id.forEach(polygonId => {
            if (polygonId !== polygon.id){
              tmp_polygon = visea.util.getObjectById(polygonId);
              let tmp_polygon_center = tmp_polygon.getCenterPoint();
              if(tmp_polygon.day_label != 'undefined') {
                tmp_polygon.day_label.left = tmp_polygon_center.x;
                tmp_polygon.day_label.top = tmp_polygon_center.y;
              }
              if (tmp_polygon !== null){
                for (let j = 0; j < tmp_polygon.points.length; j++){
                  if (tmp_polygon.points[j].x == points[i].x && tmp_polygon.points[j].y == points[i].y){
                    tmp_polygon.points[j].x = polygon.points[i].x;
                    tmp_polygon.points[j].y = polygon.points[i].y;
                  }
                }
              }
            }
          });
          visea.internals.sharedPoints[indexShared].x = polygon.points[i].x;
          visea.internals.sharedPoints[indexShared].y = polygon.points[i].y;
        }
      }
    }
    visea.internals.modifyStructure.points = new Array();
    polygon.points.forEach(point => {
      visea.internals.modifyStructure.points.push(point);
    });
    visea.util.getObjectById(polygon.id).lines = visea.polygon.getLines(polygon.id);
    visea.util.getCanvas().renderAll();
  },
  UpdatePathAutocomplete(options) {
    if (options.target instanceof fabric.Circle) {
      if (options.target.renderCircle === true && visea.internals.tmpSharePoints.length > 0){
        if (options.target.id === visea.internals.pathAutocomplete.rendercircle_id){
          return;
        }
        let lastPoint = visea.util.getLastPoint();
        if (lastPoint.left == visea.internals.tmpSharePoints[visea.internals.tmpSharePoints.length - 1].x && lastPoint.top == visea.internals.tmpSharePoints[visea.internals.tmpSharePoints.length - 1].y){
          visea.internals.pathAutocomplete.points = visea.polygon.drawPath({
            x: lastPoint.left,
            y: lastPoint.top
          },
          {
            x: options.target.left,
            y: options.target.top
          }, options.target.parent_id);
          visea.internals.pathAutocomplete.fromPoint = {
            x: lastPoint.left,
            y: lastPoint.top
          };
          visea.internals.pathAutocomplete.toPoint = {
            x: options.target.left,
            y: options.target.top
          };
          visea.internals.pathAutocomplete.polygon_id = options.target.parent_id;
          visea.internals.pathAutocomplete.rendercircle_id = options.target.id;

        }
      }
    }
    else {
      if (visea.internals.pathAutocomplete.pathPoints.length !== 0){
        visea.internals.pathAutocomplete.pathPoints.forEach(point_id => {
          visea.util.getCanvas().remove(visea.util.getObjectById(point_id));
        });
        visea.internals.pathAutocomplete.pathPoints = [];
        visea.util.getCanvas().renderAll();
      }
      if (visea.internals.pathAutocomplete.fromPoint !== null){
        visea.internals.pathAutocomplete.fromPoint = null;
      }
      if (visea.internals.pathAutocomplete.toPoint !== null){
        visea.internals.pathAutocomplete.toPoint = null;
      }
      if (visea.internals.pathAutocomplete.polygon_id !== null){
        visea.internals.pathAutocomplete.polygon_id = null;
      }
      if (visea.internals.pathAutocomplete.points !== null){
        visea.internals.pathAutocomplete.points = null;
      }
      if (visea.internals.pathAutocomplete.points !== null){

      }
    }
  },
  CleanUpNearPoint: function(){
    if (visea.internals.renderNear.circle_id !== null){
      visea.util.getCanvas().remove(visea.util.getObjectById(visea.internals.renderNear.circle_id));
    }
    visea.internals.renderNear.circle_id = null;
    visea.internals.renderNear.polygon_id = null;
  },
  UpdateNearPointPos: function(options){
    var candidates = [];
    var raw_lines = options.target.lines;
    var pos = visea.util.getCanvas().getPointer(options.e);
    raw_lines.forEach(raw_line => {
      var near_point = visea.polygon.getNearLinePoint({x: pos.x, y: pos.y}, raw_line);
      if (near_point){
        if (visea.util.isNear(near_point, {x: pos.x, y: pos.y}, 35)){
          candidates.push(near_point);
        }
      }
    });
    if (candidates.length > 0){
      let nearest = visea.util.getNearestEx({x: pos.x, y: pos.y}, candidates);
      if (options.target.id !== visea.internals.renderNear.polygon_id || visea.internals.renderNear.polygon_id === null || visea.internals.renderNear.circle_id === null){
        visea.polygon.CleanUpNearPoint();
        var circle = visea.util.generateCircle(nearest.x, nearest.y);
        circle.set({fill: 'rgba(255, 0, 255, 1)'});
        circle.line = { 0: {x: nearest.line[0].x, y: nearest.line[0].y},
          1: {x: nearest.line[1].x, y: nearest.line[1].y}};

        visea.internals.renderNear.circle_id = circle.id;
        visea.internals.renderNear.polygon_id = options.target.id;
        visea.util.getCanvas().add(circle);
      }
      else {
        visea.util.getObjectById(visea.internals.renderNear.circle_id).set({left: nearest.x, top: nearest.y});
      }
    }
    visea.util.getCanvas().renderAll();
  },
  injectPoint: function(target){
    var new_points = [];
    let polygon = visea.util.getObjectById(visea.internals.renderNear.polygon_id);
    polygon.points.forEach(point => {
      new_points.push(point);
      if (point.x === target.line[0].x && point.y === target.line[0].y) {new_points.push({x: target.left, y: target.top});}
    });
    polygon.points = new_points;
    polygon.lines = visea.polygon.getLines(polygon.id);
    target.renderCircle = true;
    target.parent_id = visea.internals.renderNear.polygon_id;
    target.set({fill: 'blue'});

    visea.util.getCanvas().renderAll();
  },
  FindCorrectTarget(target, point, full=true) {
    function pointInPolygonNested (point, vs, start, end) {
      var x = point.x, y = point.y;
      var inside = false;
      if (start === undefined) start = 0;
      if (end === undefined) end = vs.length;
      var len = end - start;
      for (var i = 0, j = len - 1; i < len; j = i++) {
          var xi = vs[i+start].x, yi = vs[i+start].y;
          var xj = vs[j+start].x, yj = vs[j+start].y;
          var intersect = ((yi > y) !== (yj > y))
              && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
          if (intersect) inside = !inside;
      }
      return inside;
    }
    let result = pointInPolygonNested(point, target.points);
    if(result) {
      return target;
    } else {
      let found = undefined;
      for(let i = 0; i < visea.util.getCanvas()._objects.length; i++){
        let obj = visea.util.getCanvas()._objects[i];
        if(obj instanceof fabric.Polygon) {
          if(pointInPolygonNested(point, full ? obj.points : obj.getCoords())){
              found = obj;
              break;
          }
        }
      };
      if(found !== undefined) return found;
    }
    return target;

  },
  AdjacencyPolygon: function(polygon=null) {
    if(polygon === null) {
      return;
    }
    if (visea.internals.adj.target !== null && visea.internals.adj.target === polygon.id) {
      Object.keys(visea.layers).forEach(layer => {
        visea.layers[layer].forEach(polyId => {
          let polygon = visea.util.getObjectById(polyId)
          polygon.opacity = 0.5;
          polygon.strokeWidth = 0.5;
          polygon.stroke = '#333333';
        })
      });
      visea.internals.overlays[visea.internals.adj.target] = new Array();
      visea.internals.adj.parents.forEach(elem => visea.internals.overlays[visea.internals.adj.target].push(elem));
      visea.internals.adj.parents = new Array();
      visea.internals.adj.target = null;
      polygon.opacity = 0.5;
      polygon.strokeWidth = 0.5;
      polygon.stroke = '#333333';

      visea.internals.mode = visea.NORMAL_MODE;
      visea.util.getCanvas().renderAll();
    }else {
      Object.keys(visea.layers).forEach(layer => {
        visea.layers[layer].forEach(polyId => {
          let polygon = visea.util.getObjectById(polyId)
          polygon.opacity = 0.2;
          polygon.strokeWidth = 0.5;
          polygon.stroke = '#333333';
        })
      });
      visea.internals.adj.parents = new Array();
      if(polygon) {
        visea.internals.adj.target = polygon.id;
        polygon.opacity = 0.8;
        polygon.strokeWidth = 5;
        polygon.stroke = '#ff0000';
      }
      if(visea.internals.overlays[visea.internals.adj.target] instanceof Array) {
        visea.internals.overlays[visea.internals.adj.target].forEach(polyId => {
          let poly = visea.util.getObjectById(polyId);
          if(poly) {
            poly.opacity = 0.5;
            poly.strokeWidth = 5;
            poly.stroke = '#0000ff';
            visea.internals.adj.parents.push(polyId);
          }
        })
      }
      visea.internals.mode = visea.ADJ_MODE;
      visea.util.getCanvas().renderAll();
    }
  },
  AdjacencyPushPolygon(polygon) {
    if(!visea.internals.adj.target) {
      polygon.opacity = 0.8;
      visea.util.getCanvas().renderAll();
      visea.internals.adj.target = polygon.id
    }else{
      if(visea.internals.adj.parents.includes(polygon.id) && visea.internals.adj.target !== polygon.id) {
        visea.internals.adj.parents = visea.internals.adj.parents.filter(elem => elem !== polygon.id);
        polygon.opacity = 0.2;
        polygon.strokeWidth = 0.5;
        polygon.stroke = '#333333';
      }else {
        if(!visea.internals.adj.parents.includes(polygon.id) && visea.internals.adj.target !== polygon.id){
          if(visea.internals.overlays[polygon.id] instanceof Array) {
            for(let i = 0; i < visea.internals.overlays[polygon.id].length; i++) {
              if(visea.internals.overlays[polygon.id][i] == visea.internals.adj.target) {
                return;
              }
            }
          }
          visea.internals.adj.parents.push(polygon.id);
          polygon.opacity = 0.5;
          polygon.strokeWidth = 5;
          polygon.stroke = '#0000ff';
        }
      }
    }
    visea.util.getCanvas().renderAll();
  }
};
visea.polygon.controls = {
  polygonPositionHandler: function(dim, finalMatrix, fabricObject) {
    var x = (fabricObject.points[this.pointIndex].x - fabricObject.pathOffset.x), y = (fabricObject.points[this.pointIndex].y - fabricObject.pathOffset.y);
    return fabric.util.transformPoint(
      { x: x, y: y },
      fabric.util.multiplyTransformMatrices(
        fabricObject.canvas.viewportTransform,
        fabricObject.calcTransformMatrix()
      )
    );
  },
  actionHandler: function(eventData, transform, x, y) {
    var polygon = transform.target, currentControl = polygon.controls[polygon.__corner],
        mouseLocalPosition = polygon.toLocalPoint(new fabric.Point(x, y), 'center', 'center'),
        polygonBaseSize = polygon._getNonTransformedDimensions(),
        size = polygon._getTransformedDimensions(0, 0),
        finalPointPosition = {
          x: mouseLocalPosition.x * polygonBaseSize.x / size.x + polygon.pathOffset.x,
          y: mouseLocalPosition.y * polygonBaseSize.y / size.y + polygon.pathOffset.y
        };
    polygon.points[currentControl.pointIndex] = finalPointPosition;
    return true;
  },
  anchorWrapper: function(anchorIndex, fn) {
    return function(eventData, transform, x, y) {
      var fabricObject = transform.target,
          absolutePoint = fabric.util.transformPoint({
            x: (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x),
            y: (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y),
          }, fabricObject.calcTransformMatrix()),
          actionPerformed = fn(eventData, transform, x, y),
          newDim = fabricObject._setPositionDimensions({}),
          polygonBaseSize = fabricObject._getNonTransformedDimensions(),
          newX = (fabricObject.points[anchorIndex].x - fabricObject.pathOffset.x) / polygonBaseSize.x,
          newY = (fabricObject.points[anchorIndex].y - fabricObject.pathOffset.y) / polygonBaseSize.y;
      fabricObject.setPositionByOrigin(absolutePoint, newX + 0.5, newY + 0.5);
      return actionPerformed;
    };
  },
  hookControls: function(polygon) {
    var lastControl = polygon.points.length - 1;
    polygon.lockMovementX = true;
    polygon.lockMovementY = true;
    polygon.cornerStyle = 'circle';
    polygon.cornerColor = 'rgba(0,0,255,0.5)';
    polygon.controls = polygon.points.reduce(function(acc, point, index) {
      acc['p' + index] = new fabric.Control({
        positionHandler: visea.polygon.controls.polygonPositionHandler,
        actionHandler: visea.polygon.controls.anchorWrapper(index > 0 ? index - 1 : lastControl, visea.polygon.controls.actionHandler),
        actionName: 'modifyPolygon',
        pointIndex: index
      });
      return acc;
    }, { });
  }
};

visea.callbacks = {
  hook: function(){
    visea.internals.canvas.on('mouse:down', function(options){
      var pos = visea.util.getCanvas().getPointer(options.e);
      var evt = options.e;
      if (evt.altKey === true  || (visea.internals.editorMode !== visea.EDITOR_POINT && 
                                   visea.internals.mode === visea.NORMAL_MODE &&
                                   visea.internals.callbacks.oneTimePolygonClick.length === 0)) {
        visea.internals.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
        return;
      }
      if(visea.internals.editorMode === visea.EDITOR_POINT) {
        if(visea.internals.callbacks.onPointClick !== null && 
           typeof visea.internals.callbacks.onPointClick === 'function')
           {
             visea.util.setViseaPoint(pos)
             visea.internals.callbacks.onPointClick(pos)
            }
        return;
      }
      
      if(options.target instanceof fabric.Polygon) {
        options.target = visea.polygon.FindCorrectTarget(options.target, pos);
        options.target.bringToFront();
      }
      visea.internals.callbacks.oneTimePolygonClick.forEach( cb => {
        cb(options.target);
      })
      if ( visea.internals.callbacks.onPolygonClick !== null &&
                typeof visea.internals.callbacks.onPolygonClick === 'function' &&
                options.target instanceof fabric.Polygon){

        visea.internals.callbacks.onPolygonClick(options);
      }
      if (visea.internals.mode === visea.NEW_MODE && visea.util.isValid(pos)){
        if (options.target !== null){
          if (typeof options.target.isStart !== 'undefined'){
            if (options.target && options.target.id == visea.internals.newPolygonArray[0].id){
              if (visea.internals.newPolygonArray.length >= 3){
                visea.polygon.generatePolygon();
                visea.polygon.drawPolygon();
              }
            }
          }
          else {
            if (visea.internals.renderNear.circle_id !== null && visea.internals.useRender){
              if (options.target instanceof fabric.Circle){
                if (options.target.id === visea.internals.renderNear.circle_id){
                  visea.polygon.injectPoint(options.target);
                }
              }
              else {
                visea.polygon.injectPoint(visea.util.getObjectById(visea.internals.renderNear.circle_id));
                options.target = visea.util.getObjectById(visea.internals.renderNear.circle_id);
                // options.e.layerX = options.target.left;
                // options.e.layerY = options.target.top;
              }
            }
            visea.polygon.addPoint(options);
          }
        }
        else {
          visea.polygon.addPoint(options);
        }
      }
      if(visea.internals.mode === visea.ADJ_MODE) {
        if (options.target instanceof fabric.Polygon) {
          let pos = visea.util.getCanvas().getPointer(options.e);
          let target = options.target; // visea.polygon.FindCorrectTarget(options.target, pos);
          if(target.id !== visea.internals.adj.target){
            visea.polygon.AdjacencyPushPolygon(target);
            visea.util.getCanvas().requestRenderAll();
          }
        }
        if(options.target instanceof fabric.Text) {
          visea.polygon.AdjacencyPushPolygon(options.target.polygon);
          visea.util.getCanvas().requestRenderAll();
        }
      }
      if (window.event.ctrlKey) {
        if (visea.internals.mode !== visea.NEW_MODE){
          visea.polygon.drawPolygon();
        }
      }
      visea.util.getCanvas().requestRenderAll();
    });
    visea.internals.canvas.on('mouse:wheel', function(options){
      //this.selection = false;
      var delta = options.e.deltaY;
      var zoom = visea.internals.canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) {zoom = 20;}
      if (zoom < 0.01) {zoom = 0.01;}
      this.zoomToPoint({ x: options.e.offsetX, y: options.e.offsetY }, zoom);
      options.e.preventDefault();
      options.e.stopPropagation();
      var vpt = visea.internals.viewportTransform;
      if (zoom < 400 / 1000) {
        vpt[4] = 200 - 1000 * zoom / 2;
        vpt[5] = 200 - 1000 * zoom / 2;
      }
      else {
        if (vpt[4] >= 0) {
          vpt[4] = 0;
        }
        else if (vpt[4] < this.getWidth() - 1000 * zoom) {
          vpt[4] = this.getWidth() - 1000 * zoom;
        }
        if (vpt[5] >= 0) {
          vpt[5] = 0;
        }
        else if (vpt[5] < this.getHeight() - 1000 * zoom) {
          vpt[5] = this.getHeight() - 1000 * zoom;
        }
      }
      if(visea.internals.editorMode === visea.EDITOR_POINT){
        let viseaPoint = visea.util.getObjectById('visea-point');
        if(viseaPoint !== null){
          viseaPoint.radius = (0.0047 * visea.util.getCanvas().height) / visea.util.getCanvas().getZoom()
          viseaPoint.strokeWidth = 3 / visea.util.getCanvas().getZoom();
          visea.util.getCanvas().renderAll();
        }
      }
      if(visea.internals.editorMode === visea.EDITOR_VISEA || visea.internals.editorMode === visea.internals.EDITOR_MULTIMEDIA) {
        visea.util.getCanvas()._objects.forEach( obj => {
          if(obj instanceof fabric.Circle) {
            obj.radius = visea.internals.sizes.circle / visea.util.getCanvas().getZoom();
            obj.strokeWidth = 0.5 / visea.util.getCanvas().getZoom();
          }else if(obj instanceof fabric.Line){
            obj.strokeWidth = visea.internals.sizes.line / visea.util.getCanvas().getZoom();
          }
        })
        visea.util.getCanvas().requestRenderAll();
      }
      //this.selection = true;
    });
    visea.internals.canvas.on('mouse:move', function(options){
      var pos = visea.util.getCanvas().getPointer(options.e);
      if (visea.internals.isDragging) {
        var e = options.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      }
      if (visea.internals.mode === visea.NEW_MODE) {
        if (visea.internals.activeLine && visea.internals.activeLine.class == 'line'){
          if (visea.internals.useRender) {visea.polygon.UpdatePathAutocomplete(options);}
          if (options.target instanceof fabric.Polygon){
            if (visea.internals.useRender){
              var pos = visea.util.getCanvas().getPointer(options.e);
              options.target = visea.polygon.FindCorrectTarget(options.target, pos, false);
              visea.polygon.renderNear(options.target, {x: pos.x, y: pos.y});
              // visea.polygon.UpdateNearPointPos(options);
            }
          }
          else {
            if (visea.internals.useRender) {visea.polygon.CleanUpNearPoint();}
          }
          var pointer = visea.internals.canvas.getPointer(options.e);
          visea.internals.activeLine.set({ x2: pointer.x, y2: pointer.y });
          var points = visea.internals.activeShape.get('points');
          points[visea.internals.newPolygonArray.length] = {
            x: pointer.x,
            y: pointer.y
          };
          visea.internals.activeShape.set({
            points: points
          });
          visea.internals.canvas.renderAll();
        }
        visea.internals.canvas.renderAll();
      }
      visea.internals.canvas.renderAll();
    });
    visea.internals.canvas.on('mouse:up', function(options){
      this.setViewportTransform(this.viewportTransform);
      visea.internals.isDragging = false;
      this.selection = true;
    });
    window.addEventListener('mouseup', function(){
      if (visea.internals.mode === visea.EDIT_MODE){
        if (visea.internals.useRender) {visea.polygon.commitModifyOnRelease();}
      }
    });

  }
};